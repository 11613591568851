<div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
  
  <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
    <wackadoo-audio-price-list-items></wackadoo-audio-price-list-items>
  </div>
  <div [ngClass]="{ 'widthViewPort30' : (screenType !== 'phone') }">
    <div class="polaroid rotate_left">
      <img src="./assets/app-images/upright bridge (5).jpg" alt="5 string upright bass">
      <div class="italic">
        I love the tone on this...
      </div>
    </div>
  </div>
</div>
