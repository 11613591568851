<div *ngIf="!isLoggedIn">
  <div id="home" class="marginBottonWholeEm">
    <ngb-carousel #carousel interval="5000">
      <ng-template ngbSlide *ngFor="let img of images; let idx = index">
        <div class="carousel-caption">
          <div class="staticCarouselText right black">
            <div class="max-width-500px">
              <div>Welcome to {{ domain }}!</div>
              <h1 class="marginTop2 marginBottom0 green" *ngIf="(screenType !== 'phone')">
                Mixing. Mastering. Vocal Editing.
              </h1>
              <h2 class="marginTop2 marginBottom0 green" *ngIf="(screenType === 'phone')">
                Mixing.
                <br/>
                Mastering.
                <br/>
                Vocal Editing.
              </h2>
              <div>
                Fast. Professional. Easy to work with.
              </div>
            </div>
          </div>
        </div>
        <div class="faded-image image{{idx}}">
          <!-- 
            We don't put the url in here. Instead, we set background-image on the  
            imageX class (using code...) in order to implement a CSS fade effect...
          -->
          <img src=" " alt="" />
        </div>
      </ng-template>
    </ngb-carousel>
    <div class="marginTopHalfEm marginLeftWholeEm marginRightWholeEm marginBottonWholeEm"
    >
      <!--
          wackadooMaximizeContentHeight
          maxHeightThingBelowSelector=".wackadoo-footer"
          [maxHeightFudgeFactor]="-16"
      -->
      <h3 class="center green marginTopWholeEm">
        Helping you bring peace, love, joy, hope, and a little soul... <span class="italic">one song at a time.</span>
      </h3>
      <div class="large marginWholeEm" [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone')}">
        <div class="center marginWholeEm " [ngClass]="{rotate_left:(screenType !== 'phone')}">
          <div class="large">
            Jazz
          </div>
          <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
            Swing, bop, straight-ahead, latin, world-beat, fusion, electronic...
          </div>
        </div>
        <div class="center marginWholeEm" [ngClass]="{rotate_right:(screenType !== 'phone')}">
          <div class="large">
            Blues, Soul, R&B, Funk
          </div>
          <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
            Music that reaches deep inside and makes you want to move.
          </div>
        </div>
        <div class="center marginWholeEm" [ngClass]="{rotate_left:(screenType !== 'phone')}">
          <div class="large">
            Gospel, Praise & Worship
          </div>
          <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
            Joyful and reverent music for comfort, inspiration, and congregational singing.
          </div>
        </div>
        <div class="center marginWholeEm" [ngClass]="{rotate_right:(screenType !== 'phone')}">
          <div class="large">
            Un-Plugged, Acoustic, Singer-Songwriter...
          </div>
          <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
            The human voice. Guitar. Piano. Percussion. Natural sound.
          </div>
        </div>
        <div class="center marginWholeEm" [ngClass]="{rotate_left:(screenType !== 'phone')}">
          <div class="large">
            Pop, Rock, Reggae, Alt, Americana...
          </div>
          <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
            Guitars, keys, bass, drums, SM58s... Let's go!
          </div>
        </div>
      </div>
      <div class="large marginTopWholeEm d-flex justify-content-around align-items-center">
        <div class="center" [ngClass]="{ 'widthViewPort35' : (screenType !== 'phone') }">
          <div class="large">
            Let me prove myself to you.
          </div>
          <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
            As a new client, you get your first song free.
            <br/>
            Like what you hear? Then hire me for your next one.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="Services" class="marginTopWholeEm marginBottonWholeEm">
    <wackadoo-audio-services></wackadoo-audio-services>
  </div>
  <div id="Portfolio" class="marginTopWholeEm marginBottonWholeEm">
    <wackadoo-audio-portfolio></wackadoo-audio-portfolio>
  </div>
  <div id="About" class="marginTopWholeEm marginBottonWholeEm">
    <wackadoo-audio-about-us></wackadoo-audio-about-us>
  </div>
  <div id="Contact" class="marginTopWholeEm marginBottonWholeEm">
    <wackadoo-audio-contact-us></wackadoo-audio-contact-us>
  </div>
</div>
<div class="marginWholeEm" *ngIf="user && isLoggedIn">
	<div class="marginWholeEm jumbotron shadow paddingTopWholeEm paddingBottomWholeEm">
		<h2>Welcome {{ user.fullName }}</h2>
		<h3> from {{ user.accountName }}!</h3>
		<br/>
		<p>
			userName: {{ user.userName }}
			<br/>
			login time: {{ user.lastAccess }})
		</p>
	</div>
</div>
