<div class="d-flex justify-content-center">
  <div class="card">
    <div class="card-header">
      Drop me a line...
    </div>
    <div class="card-body">
      <div>Steve Kuenzli</div>
      <div>{{domain}}</div>
      <div><a href="mailto:steve@{{domain}}?subject=Enquiring about your services&body=Hey Steve,%0D%0A%0D%0AI was looking around your website, and was wondering...">steve@{{ domain }}</a></div>
    </div>
  </div>
</div>
