import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientCoreRoutingModule } from 'src/app/client-core/client-core-routing.module';
import { AccountManagerCoreRoutingModule } from 'src/app/account-manager-core/account-manager-core-routing.module';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { CheckForResRepComponent } from 'src/app/client-core/pages/check-for-res-rep/check-for-res-rep.component';
import { HomeAudioComponent } from './pages/home-audio/home-audio.component';
import { AudioAboutUsComponent } from './pages/audio-about-us/audio-about-us.component';
import { AudioPoliciesComponent } from './pages/audio-policies/audio-policies.component';
import { AudioServicesComponent } from './pages/audio-services/audio-services.component';
import { AudioProcessComponent } from './pages/audio-process/audio-process.component';
import { AudioPortfolioComponent } from './pages/audio-portfolio/audio-portfolio.component';
import { AudioClientsComponent } from './pages/audio-clients/audio-clients.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { SongsComponent } from './pages/songs/songs.component';
import { AudioInBoxComponent } from './pages/audio-in-box/audio-in-box.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { ThankYouInvoiceComponent } from './pages/invoice/thank-you-invoice.component';
import { DownloadComponent } from './pages/download/download.component';
import { AudioContactUsComponent } from './pages/audio-contact-us/audio-contact-us.component';
import { UploadComponent } from './pages/upload/upload.component';

const routes: Routes = [
  // { path: '**', component: InsertSomeComponent, pathMatch: 'full' },

  { path: 'About', component: AudioAboutUsComponent, pathMatch: 'full' },
  { path: 'Contact', component: AudioContactUsComponent, pathMatch: 'full' },
  { path: 'Policies', component: AudioPoliciesComponent, pathMatch: 'full' },
  { path: 'Services', component: AudioServicesComponent, pathMatch: 'full' },
  { path: 'Process', component: AudioProcessComponent, pathMatch: 'full' },
  { path: 'Portfolio', component: AudioPortfolioComponent, pathMatch: 'full' },
  { path: 'InBox', component: AudioInBoxComponent, pathMatch: 'full' },

  { path: 'Clients', component: AudioClientsComponent, pathMatch: 'full' },
  { path: 'Projects', component: ProjectsComponent, pathMatch: 'full' },
  { path: 'Songs', component: SongsComponent, pathMatch: 'full' },

  { path: 'Invoice', component: InvoiceComponent, pathMatch: 'full' },
  { path: 'ThankYou', component: ThankYouInvoiceComponent, pathMatch: 'full' },
  { path: 'Download', component: DownloadComponent, pathMatch: 'full' },
  { path: 'Upload', component: UploadComponent, pathMatch: 'full' },

  // ** catch all unknown paths, and send them home...
  // { path: '**', redirectTo: '/', pathMatch: 'full' },

  // ** catch all unknown paths, and check to see if it's a ResourceRepository...
  { path: '**', component: CheckForResRepComponent, pathMatch: 'full' },

];

@NgModule({
  imports: [
    ClientCoreRoutingModule,
    AccountManagerCoreRoutingModule,

    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy', anchorScrolling: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    userInterfaceService: UserInterfaceService
  ) {
    userInterfaceService.overRideAngularRouterPathComponent('', HomeAudioComponent);
    userInterfaceService.overRideAngularRouterPathComponent('Pricing', AudioServicesComponent);
  }
}
