import { Component, OnInit, Renderer2, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { WResource } from 'src/app/client-core/data/resource.model';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { WField, WString } from 'src/app/client-core/data/field.model';
import { Globals } from 'src/app/client-core/services/global.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'wackadoo-download',
  templateUrl: './download.component.html',
})
export class DownloadComponent extends PageComponent implements OnInit, OnDestroy, AfterViewInit {

  project: WResource = null;
  songs: WResource [] = [];

  wavCount = 0;
  mp3Count = 0;

  domain: string = null;
  domainURL = '';
  accountName: string = null;
  accountLogoURL = '';

  inputCPWOK = false;
  inputCPW: WString = null;

  inputCPWChangedSubject: Subject<WField> = null;
  inputCPWChangedSubscription: Subscription = null;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
    public renderer: Renderer2,
    public elementRef: ElementRef,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.domain = Globals.domain;
    this.domainURL = Globals.domainURL;
    this.accountName = this.user.accountName;
    this.accountLogoURL = this.userInterfaceService.accountLogoURL;

    let projectID: number = null;
    let pidFromBrowser: number = null;

    const urlParms = this.userInterfaceService.getStartUpUrlParameters();
    this.userInterfaceService.clearStartUpUrlParameters();

    // console.log('urlParms', urlParms);
    // console.log('pageState', this.userInterfaceService.getPageState('Download', 'projectID'));


    if (urlParms && urlParms.pid) {
      pidFromBrowser = urlParms.pid;
    }

    if (urlParms && urlParms.projectID) {
      projectID = Number(urlParms.projectID);
    } else {
      projectID = this.userInterfaceService.getPageState('Download', 'projectID');
      this.userInterfaceService.clearPageState('Download', 'projectID');
    }

    if (pidFromBrowser) {
      const parms: any = {};
      parms.pid = pidFromBrowser;
      this.modalDialogService.showPleaseWait(true, true);
      this.eventServerService.loadResourceFromServer('Projects', parms).subscribe(
        (r: WResource) => {
          this.modalDialogService.showPleaseWait(false);
          this.validateProjectResource(r);
        }
      );
    } else if (projectID) {
      const parms: any = {};
      parms.projectID = projectID;
      this.modalDialogService.showPleaseWait(true, true);
      this.eventServerService.loadResourceFromServer('Projects', parms).subscribe(
        (r: WResource) => {
          this.modalDialogService.showPleaseWait(false);
          this.validateProjectResource(r);
        }
      );
    } else {

      const title = 'My apologies!';

      let msg = 'The web page code could not find a projectID to load.';
      msg += '\nPlease try again - go back and click on your Download link once more. If you came from the ThankYou page, you will find it in your PaymentConfirmation email.';
      msg += '\n(And don\'t worry, it\'s just a web page glitch. I haven\'t lost any of the work that I have done for you.)';

      this.modalDialogService.showAlert(msg, title).subscribe(
        () => {
          this.userInterfaceService.loadPage('home');
        }
      );
    }

    this.inputCPWChangedSubject = new Subject<WField>();
    this.inputCPWChangedSubscription = this.inputCPWChangedSubject.subscribe(
      (f: WField) => {
        this.validatePassword(f.value);
      }
    );

    // now we set up the password field...
    this.inputCPW = new WString('password', null);
    this.inputCPW.length = 72;
    this.inputCPW.minLength = 8;
    this.inputCPW.pattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_])[A-Za-z\\d\\W_]{4,}';
    this.inputCPW.encrypt = true;
  }

  ngOnDestroy(): void {

    // console.log('Entering ngOnDestroy()');

    if (this.inputCPWChangedSubscription) {
      this.inputCPWChangedSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.userInterfaceService.focusOnField(this.elementRef, this.inputCPW);
  }

  get isSmallFullscreenOrTablet(): boolean {
    return (this.userInterfaceService.isSmallFullScreen() || (this.screenType === 'tablet'));
  }

  requestRevision(song: WResource): void {

    const formattedBody = 'Yo, Steve!'
                          + '\n\n'
                          + 'Can you re-do some stuff on "' + song.songName.value + '" for me?'
                          + '\n\n'
                          + 'Give me a call, so we can talk it over.'
                          + '\n\n'
                          + 'Specfically, take a look at ...'
     ;
    location.href = 'mailto:steve@' + this.domain + '?subject=Revision Request: "' + song.songName.value + '" needs work!&body=' + encodeURIComponent(formattedBody);
  }

  validateProjectResource(r: WResource): void {

    // console.log('validateProjectResource()', r);

    if (r) {
      this.project = r;

      const parms: any = {};
      parms.projectID = this.project.projectID.value;
      parms.pageSize = -1;

      this.eventServerService.fireEvent('Songs', 'list', parms).subscribe(
        (e: WEvent) => {

          this.modalDialogService.showPleaseWait(false);

          if (e && (e.status === 'OK')) {

            this.songs = e.resources;

            this.wavCount = 0;
            this.mp3Count = 0;
            for (const s of this.songs) {
              if (!s.wavFile.isNull) {
                this.wavCount++;
              }
              if (!s.mp3File.isNull) {
                this.mp3Count++;
              }
            }

          } else {
            console.log(e);
            throw new Error('Unable to load songs for project: ' + this.project.projectName.value);
          }
        }
      );

    } else {

      const title = 'My apologies!';

      let msg = 'The web page code could not find the project for that link.';
      msg += '\nPlease try again - go back and click on your Download link once more. If you came from the ThankYou page, you will find it in your PaymentConfirmation email.';
      msg += '\n\nIf that does not work, please contact me directly - making sure to include your project name.';
      msg += '\n(And don\'t worry, it\'s just a web page glitch. I haven\'t lost any of the work that I have done for you.)';

      this.modalDialogService.showAlert(msg, title).subscribe(
        () => {
          this.userInterfaceService.loadPage('home');
        }
      );
    }
  }

  get previousDownloadDateMessage(): string {
    let msg = '';
    if (this.project) {
      const downloadDate: WField = this.project.getField('downloadDate');
      msg = new Date(downloadDate.value).toLocaleDateString() + ' at ' + new Date(downloadDate.value).toLocaleTimeString();
    }
    return msg;
  }

  private validatePassword(cpw: string): void {

    // console.log('Entering validatePassword(' + cpw + ')');

    this.inputCPWOK = false;

    if (!this.inputCPW.isNull && (this.project !== null)) {

      const parms: any = {};
      parms.projectID = this.project.projectID.value;
      parms.password = cpw;

      this.modalDialogService.showPleaseWait(true, true);

      this.eventServerService.fireEvent('AudioCustomer', 'validatePassword', parms).subscribe(
        (result: WEvent) => {
          try {

            this.modalDialogService.showPleaseWait(false);

            if (result) {

              // console.log('result: ' + result.status);
              // console.log('result:', result);

              if (result.status === 'OK') {
                this.inputCPWOK = true;
              }

              // now we flag the field as correct, or not...

              const selector = '[name="' + this.inputCPW.name + '"]';
              const element: HTMLElement = this.elementRef.nativeElement.querySelector(selector) as HTMLElement;

              if (this.inputCPWOK) {
              this.renderer.removeClass(element, 'fieldError');
              } else {
                this.renderer.addClass(element, 'fieldError');
              }
            }

          } catch (ex) {
            const msg = 'Download.validatePassword()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }

          // console.log('this.inputCPWOK: ' + this.inputCPWOK);

        }
      );
    }

  }

  downloadSong(song: WResource, binaryFieldName: string): void {

    const songID = song.songID.value;

    if (!this.inputCPWOK || !songID || !binaryFieldName) {

      const title = 'Missing information...';
      const msg = 'The binary field name, password and song are all required.';

      this.modalDialogService.showAlert(msg, title);

    } else {

      song.getField(binaryFieldName + 'DownloadDate').value = new Date();

      const parms: any = {};
      parms.songID = songID;
      parms.password = this.inputCPW.value;
      parms.binaryContentFieldName = binaryFieldName;

      this.modalDialogService.showPleaseWait(true, true);

      this.eventServerService.downloadFile('Songs', 'getFile', parms);
    }

  }

  exportProject(binaryFieldName: string): void {

    if (!this.inputCPWOK || (this.project == null) || !binaryFieldName) {

      const title = 'Missing information...';
      const msg = 'The binary field name, password and project (not yet loaded from server...) are all required.';

      this.modalDialogService.showAlert(msg, title);

    } else {

      const parms: any = {};
      parms.projectID = this.project.projectID.value;
      parms.password = this.inputCPW.value;
      parms.zipFileName = this.project.artistName.value + ' ' + this.project.projectName.value + '.zip';

      this.modalDialogService.showPleaseWait(true, true);

      // we had to specially construct things in the pipeline to perform
      // a zipFile export when it sees both projectID AND zipFileName
      this.eventServerService.downloadFile('Songs', 'export', parms);
    }

  }

}
