import { Component, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-audio-process',
  templateUrl: './audio-process.component.html',
})
export class AudioProcessComponent extends PageComponent implements OnInit {

  domain = '';

  ngOnInit(): void {
    super.ngOnInit();

    this.domain = Globals.domain;
  }

}
