import { Component } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WEvent } from 'src/app/client-core/data/event.model';


@Component({
  selector: 'wackadoo-upload',
  templateUrl: './upload.component.html',
})
export class UploadComponent extends PageComponent {

  startTime: Date = null;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    public utilityLibService: UtilityLibService,
    public eventServerService: EventServerService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  // ===============================================================================================
  //  This does NOT read the whole file into memory, streaming it chunk by chunk...
  // ===============================================================================================

  handleFileUpload(event: any): void {
    const file: File = event.target.files[0];
    this.startTime = new Date();

    // console.log('onload()', 'starting upload at ', this.startTime, file.name, file);

    try {

      // Both the chunkByteOffeset AND chunkNumber are 0-based...
      this.fireChunk(file, 0, 0);

    } catch (ex) {
      console.log(ex);
    }

  }

  fireChunk(file: File, chunkByteOffset: number, chunkNumber: number): void {

    const chunkSize = 1024 * 1024 * 5; // 5 MB
    const totalChunks = Math.ceil(file.size / chunkSize);

    if (chunkNumber < totalChunks) {

      // console.log('fireChunk()', file.name, chunkByteOffset, file.size, chunkNumber, totalChunks);

      const fileChunkBlob = file.slice(chunkByteOffset, chunkByteOffset + chunkSize);

      new Response(fileChunkBlob).arrayBuffer().then((fileChunk: ArrayBuffer) => {
        // do something with the arrayBuffer
        try {

          // console.log('fireChunk()', 'chunk ' + chunkNumber + ' contains ' + fileChunk.byteLength + ' bytes');

          const pctComplete = ((chunkByteOffset / file.size) * 100);
          const elapsedTimeInMilliseconds = new Date().getTime() - this.startTime.getTime();
          const currentRate = chunkByteOffset / elapsedTimeInMilliseconds;
          const estRemainingTimeInMilliseconds = (file.size - chunkByteOffset) / currentRate;

          if (chunkNumber <= totalChunks) {
            let msg = 'Upload In Progress: ' + this.utilityLibService.formatMemorySize(chunkByteOffset, 2) + ' of ' + this.utilityLibService.formatMemorySize(file.size, 2) + ' (' + pctComplete.toFixed(0) + '%)';

            if (elapsedTimeInMilliseconds > 1000) {
              msg += '\n\n';
              msg += '(est. time to complete: ' + this.utilityLibService.formatDurationAsHoursMinutesSeconds(estRemainingTimeInMilliseconds) + ')';
            }

            this.modalDialogService.showPleaseWait(msg, true);

            // (stole this conversion off the web...)

            let binary = '';
            const bytes = new Uint8Array(fileChunk);
            const len = bytes.byteLength;
            for (let j = 0; j < len; j++) {
                binary += String.fromCharCode( bytes[ j ] );
            }
            const fileChunkBase64 = btoa(binary);
            // console.log('fireChunk()', fileChunkBase64);

            // now fire the chunk to the server...

            const parms: any = {};
            parms.chunk = String(chunkNumber + 1);
            parms.totalChunks = String(totalChunks);
            parms.fileName = file.name;
            parms.fileChunk = fileChunkBase64;

            this.eventServerService.fireEvent('FileUploader', 'uploadChunk', parms).subscribe(
              (e: WEvent) => {
                // console.log(e.firstHandler + '.' + e.action + '()', e.status, e.parameters.fileName, e.parameters.chunk, e.parameters.totalChunks);

                // do the next chunk...
                this.fireChunk(file, chunkByteOffset + chunkSize, chunkNumber + 1);
              }
            );
          }

        } catch (err) {
          console.log(err);
        }
      });
    } else if (chunkNumber === totalChunks) {

      // console.log(file.name, chunkNumber, totalChunks, 'LAST CHUNK!');

      const parms: any = {};
      parms.fileName = file.name;
      parms.totalChunks = '' + totalChunks;

      this.eventServerService.fireEvent('FileUploader', 'mergeChunks', parms).subscribe(
        (e2: WEvent) => {
          // console.log(e2.firstHandler + '.' + e2.action + '()', e2.status, e2.parameters.fileName, e2.parameters.totalChunks);

          this.modalDialogService.showPleaseWait(false, true);

          let msg = '';
          msg += 'file: ' + file.name;
          msg += '\n';
          msg += 'size: ' + this.utilityLibService.formatMemorySize(file.size, 2);
          msg += '\n';
          msg += 'duration: ' + this.utilityLibService.formatDurationAsHoursMinutesSeconds(new Date().getTime() - this.startTime.getTime());
          msg += '\n';

          this.modalDialogService.showAlert(msg, 'File Upload Complete!');
        }
      );
    }


  }
}
