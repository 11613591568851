<div class="marginHalfEm">
  <div class="card">
    <div class="card-header">
      Please upload your file here...
    </div>
    <div class="card-body">
      <div>
        <input type="file" (change)="handleFileUpload($event)">
      </div>
    </div>
  </div>
</div>
