<div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
  
  <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
		<div class="card">
			<div class="card-header">
				Hello...
			</div>
			<div class="card-body">
        <div class="marginHalfEm">
          <p>
            ...and welcome to one of the most transparent pages on the internet!
          </p>
          <p>
            According to the <a href="https://www.oed.com/dictionary/wackadoo_adj" target="_blank">Oxford Dictionary</a>,
            a "wackadoo" is "an eccentric or fanatical person" - an apt moniker, since 
            <i>(full disclosure up front...)</i> 
            I've been doing this as a hobby for decades, but have now begun a professional training program, 
            and am starting to do this for hire.
            As I gain clients, I will be adding to the rather sparesly populated Portfolio you see above.
          </p>
          <div class="marginBottomWholeEm">
            I have two simple goals:
            <ul>
              <li>
                To make your music sound as great - to <i>feel</i> as great - as it did in your head when you 
                were first imagining it, whether you play it on ear buds, in the car, over the PA system of a 
                Welsh football stadium, or right next to your favorite artists on a streaming playlist.
              </li>
              <li>
                To earn your trust before I ask for your hard-earned money, because you don't know who I am, or how good a job I'm going to do for you. Yet.
              </li>
            </ul>
          </div>
          <p>
            I am so confident you'll like what you hear that I'll do your first song for free.
          </p>
          <p>
            So go ahead. Take a flier on me. You've got nothing to lose. If you like what you hear, then hire me for your next project.
          </p>
          <p>
            I hope to hear from you.
          </p>
          <div>Thanks,</div>
          <div>Steve Kuenzli</div>
          <div>{{domain}}</div>
          <div><a href="mailto:steve@{{domain}}?subject=Enquiring about your services&body=Hey Steve,%0D%0A%0D%0AI was looking around your website, and was wondering...">steve@{{domain}}</a></div>
        </div>
			</div>
		</div>
  </div>
  <div [ngClass]="{ 'widthViewPort30' : (screenType !== 'phone') }">
    <div class="polaroid rotate_right marginTop4em">
      <img src="./assets/app-images/video frame.png" alt="Portrait Of An Old Man">
      <div class="italic">
        My ugly mug...
      </div>
    </div>
    <div class="polaroid rotate_left" *ngIf="(screenType !== 'phone')">
      <img src="./assets/app-images/cutaway (7).jpg" alt="5 string bass">
      <div class="italic">
        My primary instrument, when I play out...
      </div>
    </div>
  </div>
</div>
