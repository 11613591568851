<div>
  <div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
    <div [ngClass]="{ 'maxWidthViewPort30' : (screenType !== 'phone') }">
      <div class="polaroid rotate_right">
        <img src="./assets/app-images/mic SM7B (6).jpg" alt="microphone">
        <div class="italic">
          Trust me, you do NOT want me singing for you...
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
      <div class="card">
        <div class="card-header">
          Portfolio
        </div>
        <div class="card-body">
          <p>
            Here's where I'll stick some examples for you to listen to, once I have permission to use them.
          </p>
          <p class="italic">
            (Did I mention that I'm just starting to do this? In hind sight, maybe waiting 
            to put this site up until *after* I had a few clients would have been prudent?  Yeah...)
           </p>
          <p class="italic">
            (I would stick a couple of my own songs here, except that my singing is likely to scare you off...)
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
