		<div class="card">
			<div class="card-header">
				Our name...
			</div>
			<div class="card-body">
				<div *ngIf="domain === 'closingpro.net'">
					<p>
						Why "ClosingPro"? Because transaction coordinators are detail-oriented, behind-the-scenes real estate professionals.
					</p>
					<p>
						Now, as to our company name...
					</p>
				</div>
				<p>
					According to the <a href="https://www.oed.com/dictionary/wackadoo_adj" target="_blank">Oxford Dictionary</a>, 
					a "wackadoo" is "an eccentric or fanatical person". We think that fits, because we've got a few wackadoo 
					ideas stuck in our head.
				</p>
				<div class="" *ngIf="domain !== 'wackadoo.org'">
					<p>
						We think that business is...
					</p>
					<p>
						<span class="italic">Simple</span>
					</p>
					<p>
						It's about helping you get your job done for a reasonable price.
					</p>
					<p>
						<span class="italic">Cooperative</span>
					</p>
					<p>
						You know your business problems better than we do, so we need to listen if we're going to help you.
					</p>
					<p>
						<span class="italic">Straight-Forward</span>
					</p>
					<p>
						We're not into marketing hype, industry disruption, or pretending that we're changing the world
						through our web site.  We're simply trying to provide useful tools that can help you get your job done.
						(Please check out our core business values and policies for more on how we approach our work.)
					</p>
				</div>
				<div class="" *ngIf="domain === 'wackadoo.org'">
					<p>
						We want to help you (as a ministry, church, volunteer and non-profit organization...) get the 
						tech parts of your job done, so you can focus on your calling - to serve people in need.
					</p>
					<p>
						Please, talk to us. We can only learn how to help you if we listen to you.
					</p>
					<p>
						At the end of the day, we have to answer for how we used the time, skills, and resources that 
						we have been blessed with to ease the suffering of those around us. And we strive to make 
						every day count.
					</p>
				</div>
			</div>
		</div>
