import { Component, OnInit } from '@angular/core';
import { WResource } from 'src/app/client-core/data/resource.model';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-audio-price-list-items',
  templateUrl: './audio-price-list-items.component.html',
})
export class AudioPriceListItemsComponent extends PageComponent implements OnInit {

  priceList: WResource [] = null;

  // HACK ALERT! We assume that the pricelist will have no more than 20 items on it, ever...
  isCollapsed = [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true];

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadPriceList();
  }

  togglePriceListCollapse(n: number): void {
    this.isCollapsed[n] = !this.isCollapsed[n];
  }

  loadPriceList(): void {
    const eventHandler = 'PriceList';
    const action = 'list';
    const parms: any = {};
    parms.pageSize = -1;

    this.modalDialogService.showPleaseWait(true, true);

    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (result: WEvent) => {
        try {

          this.modalDialogService.showPleaseWait(false);

          if (result.status !== 'OK') {
            throw new Error(result.message);
          }
          this.priceList = null;

          for (const pli of result.resources) {
            if (pli) {
              if (!this.priceList) {
                this.priceList = [];
              }
              this.priceList.push(pli);
            }
          }

        } catch (ex) {
          const msg = 'AudioServices.loadPriceList()\n';
          this.userInterfaceService.alertUserToException(ex, msg);
        }
      }
    );
  }
}
