<div>
  <div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
    
    <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
      <div class="card">
        <div class="card-header">
          Getting started...
        </div>
        <div class="card-body">
          <p>
            First, let me be clear: Submitting a project on the InBox page does NOT commit you to paying anything. 
          </p>
          <p>
            All it does is let me know that you'd like to set up a phone or Zoom call to discuss your project.  
            If I think I can help you, and you think I'm a good fit for your music, then at THAT point I will 
            ask you to give me a verbal go-ahead.
          </p>
          <p>
            When I'm done with the work, I will send you an email with a link to the
            Invoice/ShoppingCart page where you will be able to pay me for services rendered.
          </p>
          <p>
            If this is the first time we have worked together, your first song will be at no charge.
          </p>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'widthViewPort30' : (screenType !== 'phone') }">
      <div class="polaroid rotate_left">
        <img src="./assets/app-images/upright bridge (8).jpg" alt="bass amplifier">
        <div class="italic">
          Sometimes direct, sometimes through a pre-amp...
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
    <div [ngClass]="{ 'widthViewPort30' : (screenType !== 'phone') }">
      <div class="polaroid rotate_right marginTop4em">
        <img src="./assets/app-images/cubase (1).jpg" alt="DAW Screenshot">
        <div class="italic">
          Clearly a work in progress...
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
      <div class="card">
        <div class="card-header">
          Making sure you like what you hear...
        </div>
        <div class="card-body">
          <div class="marginBottomWholeEm">
            Once payment is received, you will be able to click on the link to your secure download page. 
            You will also receive two automated emails.
            <ul>
              <li>
                The first is your receipt from <span class="fab fa-cc-stripe fa-2x middle stripeBlue"></span>, who we use to handle online payments. 
              </li>
              <li>
                The second, from here at <span class="bold italic green">{{domain}}</span>, includes a copy of your download link for future use.
              </li>
            </ul>
          </div>
          <p>
            Note that in order to download your file(s), you will need the password you created when you submitted your first project.
          </p>
          <p>
            Once you have downloaded your file(s), if you do not <span class="bold italic green">absolutely love</span> 
            what you hear, simply let me know. You can do that via text, phone, email - or the convenient <i>"Needs Work!"</i> 
            button on the download page. At that point, I will get in touch with you to talk over changes.
          </p>
          <p>
            When the revision is ready, I'll upload it, and let you know that you should re-visit your download page.
          </p>
          <p>
            If you have any questions, feel free to email me, and I will get in touch.
          </p>
          <p>
            But if you're ready to go, just click on <span class="link" wackadooPageLoad pageName="InBox">InBox</span> to submit a project.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
