<div>
    <nav class="navbar fixed-top navbar-expand-md">

        <div class="container" wackadooMenuCloser>

            <div class="d-inline-flex align-items-center">
                <a href="http://www.{{ domain }}" class="navbar-brand link marginRight0">
                    <img class="navBarLogo" src="{{ domainLogo }}" alt="{{ domain}}" />
                </a>
            </div>

            <button class="navbar-toggler padding0 navbar-dark" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!collapsed"
                aria-label="Toggle navigation" (click)="clickBannerMenu()" wackadooStopPropagation>
                <span class="navbar-toggler-icon"></span>
            </button>

            <div 
                class="navbar-collapse" 
                [ngClass]="{'collapse':collapsed}" 
                id="navbarContent"
            >
                <ul class="navbar-nav ml-auto floatRight">

                    <li class="nav-item">
                        <a class="nav-link" (click)="scrollToTab('home')">
                            <span class="navBarAppName" [ngClass]="{ 'bold' :(currentPage == 'home') }">
                                Home
                            </span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" (click)="scrollToTab('Services')">
                            <span class="navBarAppName" [ngClass]="{ 'bold' : (currentPage == 'Services') }">
                                Services
                            </span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" (click)="scrollToTab('Portfolio')">
                            <span class="navBarAppName" [ngClass]="{ 'bold' : (currentPage == 'Portfolio') }">
                                Portfolio
                            </span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" (click)="scrollToTab('About')">
                            <span class="navBarAppName" [ngClass]="{ 'bold' : (currentPage == 'About') }">
                                About
                            </span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" (click)="scrollToTab('Contact')">
                            <span class="navBarAppName" [ngClass]="{ 'bold' : (currentPage == 'Contact') }">
                                Contact
                            </span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" (click)="loadPage('Process')">
                            <span class="navBarAppName" [ngClass]="{ 'bold' : (currentPage == 'Process') }">
                                Process
                            </span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" (click)="loadPage('InBox')">
                            <span class="navBarAppName" [ngClass]="{ 'bold' : (currentPage == 'InBox') }">
                                InBox
                            </span>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" (click)="loadPage('Policies')">
                            <span class="navBarAppName" [ngClass]="{ 'bold' : (currentPage == 'Policies') }">
                                Policies
                            </span>
                        </a>
                    </li>

                    <!-- we're never going to show this button to the public. You have to know to hit /login to get there....-->
                    <li class="nav-item" *ngIf="!isLoggedIn && showLoginMenu">
                        <a class="nav-link" role="button" (click)="loadPage('login')">Login</a>
                    </li>

                    <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right" *ngIf="isLoggedIn">
                        <a class="nav-link" ngbDropdownToggle id="navbarDropdown4" role="button" wackadooStopPropagation>
                            <span class="accountLogo"><img class="userLogo" src="{{ accountLogoURL }}" alt="" /></span>
                            <span class="marginLeftQuarterEm marginRightQuarterEm">{{ userName }}</span>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="navbarDropdown4" class="dropdown-menu inherit-font-size">
                            <span class="dropdown-header">{{ userRole }}</span>
                            <span class="dropdown-header">{{ accountName }}</span>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" (click)="logout()">Logout</a>
                        </div>
                    </li>

                </ul>
            </div>

        </div>

    </nav>
</div>
