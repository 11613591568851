import { NgModule, enableProdMode, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { EditorModule } from '@tinymce/tinymce-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// common stuff pulled in by ALL web-app modules ...
import { Globals } from 'src/app/client-core/services/global.service';
import { ClientCoreModule } from 'src/app/client-core/client-core.module';
import { ClientCoreRoutingModule } from 'src/app/client-core/client-core-routing.module';

import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { BusinessRuleService } from 'src/app/client-core/services/business-rule.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { BusinessRules } from 'src/app/client-core/services/business-rules.model';

import { AccountManagerCoreModule } from 'src/app/account-manager-core/account-manager-core.module';

// audio UX components
import { AudioBannerComponent } from './ui/audio-banner/audio-banner.component';
import { AudioFooterComponent } from './ui/audio-footer/audio-footer.component';

// audio page components
import { HomeAudioComponent } from './pages/home-audio/home-audio.component';
import { AudioServicesComponent } from './pages/audio-services/audio-services.component';
import { AudioPriceListItemsComponent } from './ui/audio-price-list-items/audio-price-list-items.component';
import { AudioAboutUsComponent } from './pages/audio-about-us/audio-about-us.component';
import { AudioPoliciesComponent } from './pages/audio-policies/audio-policies.component';
import { AudioProcessComponent } from './pages/audio-process/audio-process.component';
import { AudioInBoxComponent } from './pages/audio-in-box/audio-in-box.component';
import { AudioInBoxDetailContentComponent } from './pages/audio-in-box/audio-in-box-detail-content/audio-in-box-detail-content.component';
import { AudioInBoxDetailButtonBarComponent } from './pages/audio-in-box/audio-in-box-detail-button-bar/audio-in-box-detail-button-bar.component';
import { AudioClientsComponent } from './pages/audio-clients/audio-clients.component';
import { AudioClientDetailContentComponent } from './pages/audio-clients/audio-client-detail-content/audio-client-detail-content.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectDetailButtonBarComponent } from './pages/projects/project-detail-button-bar/project-detail-button-bar.component';
import { ProjectDetailContentComponent } from './pages/projects/project-detail-content/project-detail-content.component';
import { SongsComponent } from './pages/songs/songs.component';
import { SongDetailContentComponent } from './pages/songs/song-detail-content/song-detail-content.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { CustomerComponent } from './pages/invoice/customer/customer.component';
import { ThankYouInvoiceComponent } from './pages/invoice/thank-you-invoice.component';
import { DownloadComponent } from './pages/download/download.component';
import { AudioPortfolioComponent } from './pages/audio-portfolio/audio-portfolio.component';
import { AudioContactUsComponent } from './pages/audio-contact-us/audio-contact-us.component';
import { AudioPriceListItemsContentComponent } from './ui/audio-price-list-items/audio-price-list-items-content/audio-price-list-items-content.component';
import { UploadComponent } from './pages/upload/upload.component';

// enable production mode code operation
enableProdMode();

///////////////////////////////////////
// ROOT application components...
///////////////////////////////////////

Globals.domain = 'wackadoo.audio';
Globals.domainLogoURL = './assets/pics/' + Globals.domain + '.png';

document.title = Globals.domain;

// app initialization stuff
export function initializeUserAuthentication(userAuthService: UserAuthService): () => Promise<any> {
  return (): Promise<any> => {
    return userAuthService.bootupInitialization();
  };
}


@NgModule({
  declarations: [
    AppComponent,

    AudioBannerComponent,
    AudioFooterComponent,

    HomeAudioComponent,
    AudioServicesComponent,
    AudioPriceListItemsComponent,
    AudioAboutUsComponent,
    AudioPoliciesComponent,
    AudioProcessComponent,
    AudioInBoxComponent,
    AudioInBoxDetailContentComponent,
    AudioInBoxDetailButtonBarComponent,
    AudioClientsComponent,
    AudioClientDetailContentComponent,
    ProjectsComponent,
    ProjectDetailButtonBarComponent,
    ProjectDetailContentComponent,
    SongsComponent,
    SongDetailContentComponent,
    InvoiceComponent,
    CustomerComponent,
    ThankYouInvoiceComponent,
    DownloadComponent,
    AudioPortfolioComponent,
    AudioContactUsComponent,
    AudioPriceListItemsContentComponent,
    UploadComponent,
  ],
  imports: [
    // Angular stuff...
    BrowserModule,
    HttpClientModule,
    FormsModule,

    // 3rd party stuff everybody uses...
    NgbModule,
    FontAwesomeModule,
    ChartsModule,
    EditorModule,

    // top-level routing module...
    AppRoutingModule,

    // common web app components
    ClientCoreModule,
    ClientCoreRoutingModule,

    // ROOT application module
    AccountManagerCoreModule,

  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeUserAuthentication, deps: [UserAuthService], multi: true},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    HomeAudioComponent,
    ProjectDetailButtonBarComponent,
    ProjectDetailContentComponent,
    AudioInBoxDetailContentComponent,
    AudioInBoxDetailButtonBarComponent,
    SongDetailContentComponent,
    AudioClientDetailContentComponent,
  ]
})
export class AppModule {

  constructor(
    private _businessRuleService: BusinessRuleService,
    // The only reason we included UserInterfaceService here is that it
    // HAS to be loaded PRIOR to the initial page load in order to catch
    // the NavigationStart routing event for that initial page load
    public userInterfaceService: UserInterfaceService,
  ) {
    this.loadBusinessRules();
  }

  loadBusinessRules(): void {

    const rules: BusinessRules = {

        purchaserRole: 'admin',

        firstEHPage: 'InBox',

        defaultPageSizes: {
            Applications: 10
            , Clients: 25
            , Projects: 25
            , Songs: 25
        },

        defaultActions : {
        },

        otherMenuItems: [
        ],

        otherPublicPages: [
          'Process',
          'InBox',
          'AboutUs',
          'Portfolio',
          'Policies',
          'Invoice',
          'ThankYou',
          'Download',
          'Upload',
        ],

        menuRules: [
          {
            _selection: '*',
            _role: '*',

            InBox :  { role: 'admin+'}
            , Clients:  { role: 'admin+'}
            , Projects :  { role: 'admin+'}
            , Songs:  { role: 'admin+'}
            , Invoice :  { role: 'admin+'}
            , ThankYou :  { role: 'admin+'}
            , Download : { role: 'admin+'}

            , CustomTemplates : {
              subMenu: {
                EMailTemplates : { role : 'admin+' }
                , ReportTemplates : { role : 'admin+' }
              }
            }

            , ECommerce : {
              subMenu: {
                PriceList : { role : 'admin+' }
                , PriceListPackageLinks : { role : 'admin+' }
                , ShoppingCarts : { role : 'admin+' }
                , ShoppingCartItems : { role : 'admin+' }
                , ShoppingCartLog : { role : 'admin+' }
              }
            }

            , 'System Mgt' :  {
              subMenu: {
                Administration :  { role: 'sysadmin'}
                , Accounts :  { role: 'sysadmin'}
                , Applications :  { role: 'sysadmin'}
                , ApplicationMetaData :  { role: 'sysadmin'}
                , AppPricing :  { role: 'sysadmin'}
                , RegisteredApplications :  { role: 'sysadmin'}
                , Users :  { role: 'sysadmin'}
                , Roles :  { role: 'sysadmin'}
                , SMTPServers : { role: 'sysadmin'}
              }
            }

            , EMailErrors: { role : 'admin+' }
            , BackupRestore : { role : 'admin+' }
            , Reports : { role : 'admin+' }

          }
        ],

        menuIcons: {
            // Standard stuff...
            Administration: 'cog'
            , SecurityServices: 'lock'
            , BackupService: 'save'
            , Reports: 'print'
            , CustomTemplates: 'file-alt'
            , BackupRestore:  'cloud'
            , ServerMonitor: 'server'
            , SearchService: 'search'
            , SearchEngineUpdater: 'redo'
            , Usage: 'signal'
            , LogExtractor: 'share-alt'
            , BackupCleanupService: 'trash'
            , LogCleanupService: 'trash-alt'
            , Broadcast: 'volume-up'
            , EMailTemplates: 'envelope-square'
            , ReportTemplates: 'file-alt'
            , ImportTemplates: 'file-upload'
            , ImportTemplateFields:  'file-import'
            , EMailer : 'envelope'
            , BulkEMailer: 'mail-bulk'
            , EMailErrors: 'exclamation-triangle'
            , SMTPServers: 'mail-bulk'

            // Account Manager app
            , Accounts: 'users'
            , Users: 'user'
            , Applications: 'window-restore'
            , RegisteredApplications: 'wrench'
            , Roles: 'user'
            , AppPricing: 'dollar-sign'
            , Pricing: 'dollar-sign'
            , Billing: 'credit-card'
            , NewCustomer: 'users-cog'
            , NewCustomerTruncator: 'cut'
            , DBConnectionPool: 'database'
            , EventBroadcaster: 'bullhorn'
            , TransactionLog: 'clipboard'
            , SocketEventListener: 'satellite-dish'
            , EMailErrorWebHook: 'satellite-dish'
            , UsageTruncator: 'cut'
            , ValidateUserEmail: 'user-check'
            , ValidateAccountEmail: 'user-check'
            , SystemEmailFormatter: 'mail-bulk'
            , ApplicationMetaData: 'comment-dots'

            // sub-menu icons...
            , 'System Mgt': 'toolbox'

            // audio app icons...
            , InBox : 'inbox'
            , Clients:  'users'
            , Projects: 'folder-open'
            , Songs: 'music'
            , Invoice: 'file-invoice-dollar'
            , Download: 'file-export'

            // shopping cart stuff
            , ECommerce : 'money-check-alt'
            , PriceList : 'file-invoice-dollar'
            , PriceListPackageLinks : 'layer-group'
            , Donate : 'donate'
            , ThankYou : 'clipboard-check'
            , ShoppingCarts : 'shopping-cart'
            , ShoppingCartItems : 'cart-plus'
            , ShoppingCartLog: 'archive'

        },

        serverRequestRules: [
        ],

        serverResponseRules: [
        ],

        readOnlyOnceRules: [
        ],

        menuClickRules: [
        ],

        foreignKeysThatMustBePopulated: {
        },

        importFormatRules: {
        },

        editableReportSystemTemplates: [
        ],

    };

    this._businessRuleService.loadRules('AccountManager', rules);
  }

}
